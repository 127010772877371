<template>
    <v-form ref="disclosures">
        <form-section
            :sectionTitle="$t('applications.important-info')"
            wideFirstCol
        >
            <template v-slot:col-1>
                <div v-for="disclosure in disclosuresData" :key="disclosure.id">
                    <applicant-disclosure
                        :disclosureData="disclosure"
                        @update:disclosure="
                            update(disclosure.id, 'root', $event)
                        "
                    ></applicant-disclosure>
                </div>
            </template>
        </form-section>
    </v-form>
</template>
<script>
import InteractsWithEntities from "@components/application/mixins/InteractsWithEntities";

export default {
    name: "applicant-disclosurers",
    components: {
        ApplicantDisclosure: () => import("./ApplicantDisclosure"),
    },
    mixins: [InteractsWithEntities],
    props: {},
    data() {
        return {
            entityIdKey: "id",
            name: this.$t("applications.important-info"),
        };
    },
    computed: {
        disclosuresData() {
            return this.entitiesData.map((item) => {
                item.label = this.$t(`applications.${item.name}`);
                return item;
            });
        },
    },
    methods: {
        validate() {
            return this.$refs["disclosures"].validate();
        },
    },
};
</script>

<style scoped>
</style>