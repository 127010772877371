var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "disclosures" },
    [
      _c("form-section", {
        attrs: {
          sectionTitle: _vm.$t("applications.important-info"),
          wideFirstCol: ""
        },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return _vm._l(_vm.disclosuresData, function(disclosure) {
                return _c(
                  "div",
                  { key: disclosure.id },
                  [
                    _c("applicant-disclosure", {
                      attrs: { disclosureData: disclosure },
                      on: {
                        "update:disclosure": function($event) {
                          return _vm.update(disclosure.id, "root", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              })
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }